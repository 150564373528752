import { Animation, AnimationController } from '@ionic/angular';

export const fadeIn = (baseEl: any, opts?: any) => {
  // console.log('opts.enteringEl:'  + opts.enteringEl); //Entering Element - New Page
  // console.log('opts.leavingEl:'  + opts.leavingEl);   //Leaving Element - Current Page
  const anim1 = new AnimationController().create()
    .addElement(opts.leavingEl)
    .duration(500)
    .iterations(1)
    .easing('ease-out')
    .fromTo('opacity', '1', '0.0');
  let anim2 = new AnimationController().create()
    .addElement(opts.enteringEl)
    .duration(500)
    .iterations(1)
    .easing('ease-out')
    .fromTo('opacity', '0.0', '1');
  anim2 = new AnimationController().create()
    .duration(500)
    .iterations(1)
    .addAnimation([anim1, anim2]);
  return anim2;
};
