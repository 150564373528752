import { Component, OnInit, ViewChild } from '@angular/core';
import {NavController} from '@ionic/angular';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {
  @ViewChild('btnConfirm') btnConfirm;

  constructor(private navController: NavController) {

  }

  onKeydown(ev) {
    if (ev.srcElement.value.length >= 3) {
      this.btnConfirm.nativeElement.classList.remove('disabled');
    } else {
      this.btnConfirm.nativeElement.classList.add('disabled');
    }
  }

  ignor(){
    this.navController.navigateForward('/next-level');
  }


  confirm(){
    this.navController.navigateForward('/next-level', { state: { enterEmail: true } });
  }



  ngOnInit() {}

}
