import {AfterViewInit, Component, NgZone, OnInit, ViewChild} from '@angular/core';

// import Swiper core and required modules
import SwiperCore, { Swiper, Virtual } from 'swiper';
import {SwiperComponent} from 'swiper/angular';

// install Swiper modules
SwiperCore.use([Virtual]);

@Component({
  selector: 'app-new-email',
  templateUrl: './new-email.component.html',
  styleUrls: ['./new-email.component.scss'],
})
export class NewEmailComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent ;

  swiperIndex = 0;

  public constructor(private _ngZone: NgZone) {

  }

  goTo(index){
    this.swiper.swiperRef.slideTo(index, 250);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const mySwiper = this.swiper.swiperRef;

    mySwiper.on('slideChange', async (e) => {
      // TO fix slider on dragEnd
      try {
        this.swiperIndex = this.swiper.swiperRef.activeIndex;

        this._ngZone.run(() => {
          this.swiperIndex = this.swiper.swiperRef.activeIndex;
        });
      }
      catch (error) {}
    });
  }


}
