import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  @Input() source: any = '';
  @Input() text: any = '';
  @Input() showAvatar: any = false;
  @Input() who: any = '';

  @Input() loading = true;

  constructor() { }

  ngOnInit() {
    const time = this.who === 'A' ? 0 : 2000;
    setTimeout(()=>{
      this.loading = false;
    },time);
  }

}
