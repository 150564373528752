import { Component } from '@angular/core';
import { fadeIn } from './animations/fadeIn';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public fadeIn;

  constructor() {
    this.fadeIn = fadeIn;
  }
}
